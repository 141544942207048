import Promise from "promise-polyfill";
import "whatwg-fetch";
import "babel-polyfill";
import getCookie from "./tc/tc-cookie";

if (!window.Promise) {
  window.Promise = Promise;
}

if (typeof NodeList.prototype.forEach !== "function") {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

const TOKEN = getCookie("csrftoken");
const API_VERSION = "v1";
const API_URLS = {
  employee: {
    details: `/api/${API_VERSION}/employee/details`,
    contract: `/api/${API_VERSION}/employee/contract`,
    notifications_count: `/api/${API_VERSION}/employee/notification_count`,
  },
  notifications: {
    history: `/api/${API_VERSION}/notifications/history`,
    show: `/api/${API_VERSION}/notifications/show`, // `/id`
    address_conformation_deadlines: `/api/${API_VERSION}/notifications/deadlineaddress`,
    arrival_confirmation: `/api/${API_VERSION}/notifications/arrival_confirmation`,
    confirm_arrival: `/api/${API_VERSION}/notifications/arrival_confirmation/confirm`,
    deny_arrival: `/api/${API_VERSION}/notifications/arrival_confirmation/decline`,
  },
  surveys: {
    list: `/api/${API_VERSION}/surveys/list`,
    history: `/api/${API_VERSION}/surveys/history`,
    show: `/api/${API_VERSION}/surveys/show`, // `/id`
  },
  inbox: {},
  salary: {
    payslips: `/api/${API_VERSION}/salary/list`, // ?year=
    payslip_years: `/api/${API_VERSION}/salary/year_list`,
    annual_payslips: `/api/${API_VERSION}/salary/annual/list`,
    paydate: `/api/${API_VERSION}/salary/paydate`,
    payments: `/api/${API_VERSION}/salary/payments`,
  },
  helpdesk: {
    categories: `/api/${API_VERSION}/helpdesk/categories`,
    subcategories: `/api/${API_VERSION}/helpdesk/subcategories`,
    work_locations: `/api/${API_VERSION}/helpdesk/work-locations`,
    questions: `/api/${API_VERSION}/helpdesk/questions`,
    top_questions: `/api/${API_VERSION}/helpdesk/questions/top_questions`,
    all_inbox_threads: `/api/${API_VERSION}/helpdesk/support-requests`,
    delete_inbox_thread: `/api/${API_VERSION}/helpdesk/support-request`,
    send: `/api/${API_VERSION}/helpdesk/support-request/send`,
    submit_recovery: `/api/${API_VERSION}/helpdesk/recovery-request/send`,
    count_new_messages: `/api/${API_VERSION}/helpdesk/support-requests/count-new-messages`,
  },
  leave: {
    statuses: `/api/${API_VERSION}/leave`,
    request_list: `/api/${API_VERSION}/leave/absence-requests`,
    cancel_request: `/api/${API_VERSION}/leave/absence-requests/delete`,
    request: `/api/${API_VERSION}/leave/absence-requests/send`,
    request_by_id: `/api/${API_VERSION}/leave/absence-requests/show`,
    types: `/api/${API_VERSION}/leave/leave_types`,
    status: `/api/${API_VERSION}/leave/leave_status`,
  },
  news: `/api/${API_VERSION}/news`,
  apps: `/api/${API_VERSION}/apps`,
  topdesk: `/api/${API_VERSION}/topdesk`,
  planning: {
    show: `/api/${API_VERSION}/planning`,
    reschedule_list_create: `/api/${API_VERSION}/planning/reschedule`,
    reschedule_delete: `/api/${API_VERSION}/planning/reschedule/delete`,
  },
  transport_planning: {
    list_trips: `/api/${API_VERSION}/transportplanning/trip`,
    retrieve_trip: `/api/${API_VERSION}/transportplanning/trip`,
  },
  waffle: {
    status: `/waffle_status`,
  },
};

//keys for sessionStorage / localStorage
const STORAGE_KEYS = {
  user: "otto-user",
  faq_categories: "faq-categories",
};
const LANGUAGES = ["en", "bg", "nl", "pl", "ro", "sk", "uk"];

export { API_URLS, LANGUAGES, TOKEN, STORAGE_KEYS };
